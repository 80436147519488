import axios from 'axios';
import authHeader from "@/services/auth-header";
import ConstApiUrls from "@/services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FPotensiFileService {
    getApiUrl() {
        return API_URL
    }
    getAllFPotensiFile(){
        return axios.get(API_URL + `getAllFPotensiFile`, { headers: authHeader() });
    }
    getAllFPotensiFileContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFPotensiFile`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFPotensiFileContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFPotensiFileById(id){
        return axios.get(API_URL + `getFPotensiFileById/${id}`, { headers: authHeader() });
    }
    getAllFPotensiFileByParent(id){
        return axios.get(API_URL + `getAllFPotensiFileByParent/${id}`, { headers: authHeader() });
    }


    updateFPotensiFile(item){
        return axios.put(API_URL + `updateFPotensiFile/${item.id}`, item, {headers: authHeader()})
    }
    createFPotensiFile(item){
        return axios.post(API_URL + `createFPotensiFile`, item, {headers: authHeader()})
    }
    deleteFPotensiFile(id){
        return axios.delete(API_URL + `deleteFPotensiFile/${id}`, {
            headers: authHeader()
        });
    }

    deleteFPotensiFileAvatarByParent(fkegiatanBean){
        return axios.delete(API_URL + `deleteFPotensiFileAvatarByParent/${fkegiatanBean}`, {
            headers: authHeader()
        });
    }
    createFPotensiFileAvatar(item){
        return axios.post(API_URL + `createFPotensiFileAvatar`, item, {headers: authHeader()})
    }

    deleteAllFPotensiFile(itemIds){

        return axios.delete(API_URL + `deleteAllFPotensiFile`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FPotensiFileService()